import React from 'react'
import RegisterPage from './components/registerPage'

const App = () => {
  return (
    <div>
      <RegisterPage />
    </div>
  )
}

export default App
