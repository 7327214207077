import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../images/ISG grip.png";
import SunPharma from "../images/sunLogo.png";
import Sygnus from "../images/sygnus.png";
import logo1 from "../images/IsgLogo1.png";

const RegisterPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobile] = useState("");
  const [place, setPlace] = useState("");

  const handleRegister = async (e) => {
    try {
      e.preventDefault();
      if (!name.trim()) {
        toast.error("Please enter name", { duration: 2000 });
        return;
      }
      const trimmedEmail = email.trim();

      if (!trimmedEmail) {
        toast.error("Please enter email", { duration: 2000 });
        return;
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailPattern.test(trimmedEmail)) {
        toast.error("Please enter a valid email", { duration: 2000 });
        return;
      }

      if (!/^\d{10}$/.test(mobileNo)) {
        toast.error("Please enter Mobile no with exactly 10 digits", {
          duration: 2000,
        });
        return;
      }
      if (!place.trim()) {
        toast.error("Please enter place", { duration: 2000 });
        return;
      }
      const { data } = await axios.post(
        "https://isggrip.digilateral.com/api/register",
        {
          name,
          email,
          mobileNo,
          place,
        }
      );

      toast.success(data && data.message, { duration: 2000 });
      setName("");
      setEmail("");
      setMobile("");
      setPlace("");
    } catch (error) {
      toast.error(error.response.data.message, { duration: 2000 });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-orange-400">
      <div className="border border-gray-300 shadow-md rounded-lg max-w-md w-full p-6 bg-white">
        <div className="flex justify-end">
          <img src={logo1} alt="logo" width="60px" height="60px" />
        </div>
        <div className="">
          <div className="flex justify-center">
            <img src={Logo} alt="logo" width="190px" height="190px" />
          </div>
          <div class="mb-4">
            <label
              // for="name"
              class="block mb-2 text-sm font-medium text-gray-900"
            >
              Name<span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              id="name"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              // placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div class="mb-4">
            <label
              // for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email<span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              // placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div class="mb-4">
            <label
              // for="mobile"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Mobile No<span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              id="mobile"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              // placeholder="Mobile"
              value={mobileNo}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              // for="place"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Place<span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              id="place"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              // placeholder="Place"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
              required
            />
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleRegister}
              type="submit"
              className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Register
            </button>
            <Toaster />
          </div>
          <div className="flex items-center w-full justify-center  pt-10 font-mono gap-[3px] mr-8">
            <p className="text-[11px] mt-6 text-center font-sans italic">
              Academic partner
            </p>
            <img
              src={Sygnus}
              alt=""
              className="mr-8"
              width="50px"
              height="50px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
